import React from 'react';

import { FormikProps } from 'formik';

import { QUESTION_TYPES } from 'src/constants/quiz.constant';
import { FormMenuItem, FormTextField } from 'src/pages/profile/components/general-information.styled';

import { QuestionContainer, QuestionRow, QuestionTitle } from './index.styled';
import ShortQuestion from './short-question.component';
import SingleChoice from './single-choice.component';

interface IQuestionProps {
  type: 'short-paragraph' | 'single-choice';
  questionIndex: string;
  formik: FormikProps<any>;
  disabled?: boolean;
  formStatus?: { isReset: boolean };
}

const Question = ({ type, questionIndex, formik, disabled, formStatus }: IQuestionProps) => {
  const SINGLE_CHOICE = 'single-choice';
  const SHORT_PARAGRAPH = 'short-paragraph';
  const EMPTY_TEXT = '';
  const MAX_LENGTH_255 = 255;

  const EMPTY_SINGLE_CHOICE = {
    questionText: EMPTY_TEXT,
    questionType: SINGLE_CHOICE,
    point: EMPTY_TEXT,
    answer: [
      {
        answer: EMPTY_TEXT,
        isTrue: true,
      },
    ],
  };

  const EMPTY_SHORT = {
    questionText: EMPTY_TEXT,
    questionType: SHORT_PARAGRAPH,
  };

  const resetEmptyQuestionOption = (questionType: string) => {
    if (questionType === SINGLE_CHOICE) {
      formik.setFieldValue(`question[${questionIndex}]`, EMPTY_SINGLE_CHOICE);
    }
    if (questionType === SHORT_PARAGRAPH) {
      formik.setFieldValue(`question[${questionIndex}]`, EMPTY_SHORT);
    }
  };
  const handleQuestionTypeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    resetEmptyQuestionOption(e.target.value);
    formik.setFieldValue(`question[${questionIndex}].questionType`, e.target.value);
  };

  const handleQuestionTitleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldValue(`question[${questionIndex}].questionText`, e.target.value);
  };

  const touched = formik.touched as any;
  const errors = formik.errors as any;

  return (
    <QuestionContainer>
      <QuestionRow variants="head">
        <QuestionTitle variants="title">Question {+questionIndex + 1}</QuestionTitle>
        <QuestionTitle variants="type">Question Type</QuestionTitle>
      </QuestionRow>
      <QuestionRow variants="body">
        <QuestionTitle variants="title">
          <FormTextField
            disabled={disabled}
            variant="outlined"
            fullWidth
            placeholder="Enter question"
            value={formik.values?.question?.[questionIndex].questionText}
            name={`question[${questionIndex}].questionText`}
            onChange={handleQuestionTitleChange}
            onBlur={formik.handleBlur}
            error={
              touched?.question?.[questionIndex]?.questionText && !!errors?.question?.[questionIndex]?.questionText
            }
            helperText={
              touched?.question?.[questionIndex]?.questionText && errors?.question?.[questionIndex]?.questionText
            }
            inputProps={{ maxLength: MAX_LENGTH_255 }}
            autoFocus
          />
        </QuestionTitle>
        <QuestionTitle variants="type">
          <FormTextField
            variant="outlined"
            fullWidth
            select
            disabled={disabled}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
            onChange={handleQuestionTypeChange}
            value={formik.values.question[questionIndex]?.questionType}
            name={`question[${questionIndex}].questionType`}
          >
            {QUESTION_TYPES.map((item, index) => (
              <FormMenuItem key={index} value={item.value}>
                {item.label}
              </FormMenuItem>
            ))}
          </FormTextField>
        </QuestionTitle>
      </QuestionRow>
      {formik.values.question[questionIndex]?.questionType === SINGLE_CHOICE ? (
        <SingleChoice formStatus={formStatus} disabled={disabled} formik={formik} questionIndex={questionIndex} />
      ) : (
        <ShortQuestion formStatus={formStatus} disabled={disabled} formik={formik} questionIndex={questionIndex} />
      )}
    </QuestionContainer>
  );
};

export default Question;
