import React from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import styled, { css } from 'styled-components';

import CustomFormDialog from 'src/components/formDialog';
import { FORMAT_TIME_AM_PM } from 'src/constants/common';
import { InfoText } from 'src/pages/payment-managerment/InfoText';
import { IPaymentHistory } from 'src/services/users';

const DEFAULT_VALUE = 0;
interface IPaymentHistoryDetail {
  open?: boolean;
  _onCloseModal: () => void;
  dataDetail?: IPaymentHistory;
}
function PaymentHistoryDetail({ open, _onCloseModal, dataDetail }: IPaymentHistoryDetail) {
  const fullName = dataDetail?.guest
    ? dataDetail?.guest.fullName
    : `${dataDetail?.user?.surname}, ${dataDetail?.user?.givenName}`;
  const emailAddress = dataDetail?.guest ? dataDetail?.guest?.email : `${dataDetail?.user?.emailAddress}`;
  const phoneNumber = dataDetail?.guest
    ? `${dataDetail?.guest?.areaCode} ${dataDetail?.guest?.mobilePhone}`
    : `${dataDetail?.user?.areaCode} ${dataDetail?.user?.mobilePhone}`;
  const refrence =
    dataDetail?.type === 'buy-ticket'
      ? dataDetail?.event?.title
      : dataDetail?.type === 'donation' && dataDetail?.receiveAccount === 'S83SS0071H'
      ? 'NSAA'
      : dataDetail?.type === 'pay-membership-fee'
      ? fullName
      : 'NSAA - NYPS Bursary Fund';
  const isDonation = dataDetail?.type === 'donation';
  const paymentStatus = (
    <ButtonStatus variants={dataDetail && dataDetail.status} disableRipple>
      {dataDetail ? dataDetail.status : ''}
    </ButtonStatus>
  );

  const renderTotal = () => {
    if (!dataDetail) return 0;

    if (dataDetail?.type === 'pay-membership-fee') {
      return dataDetail?.amount || 0;
    }

    return dataDetail?.registration?.subTotal! - dataDetail?.registration?.discount!;
  };

  return dataDetail ? (
    <CustomFormDialog
      open={open || false}
      _onCloseModal={_onCloseModal}
      submitText="Close"
      title="Payment Detail"
      hiddenCloseButton
      noClickOutSide={true}
      modalStyles={{
        marginLeft: 0,
        marginRight: 0,
      }}
      minWidth="960px"
    >
      <Box>
        <Box display="flex" justifyContent="space-between" mt={3} ml={6} mr={6} mb={9}>
          <Box mr={1} flex={1}>
            <Typography>Payer Info</Typography>
            <InfoText label="Name" value={fullName} />
            <InfoText label="NSAA ID" value={dataDetail?.user?.username} />
          </Box>
          <Box ml={1} mt={3} flex={1}>
            <InfoText label="Email Address" value={emailAddress} />
            <InfoText label="Phone Number" value={phoneNumber} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3} ml={6} mr={6} mb={9}>
          <Box mr={1} flex={1}>
            <Typography>Payer Info</Typography>
            {isDonation && <InfoText label="Amount" value={dataDetail?.amount} />}
            <InfoText label="Payment Type" value={dataDetail?.type} />
            <InfoText label="Reference" value={refrence} />
            <InfoText label="Last Modified At" value={moment(+dataDetail?.updatedAt!).format(FORMAT_TIME_AM_PM)} />
            <InfoText label="Payment Status" value={paymentStatus} />
            {!isDonation && (
              <>
                <InfoText label="Total Number of tickets purchased:" value={dataDetail?.registration?.quantity} />
                <InfoText
                  label="Number of Halal Ticket"
                  value={
                    dataDetail?.registration?.ticketForHalal ? dataDetail?.registration?.ticketForHalal : DEFAULT_VALUE
                  }
                />
                <InfoText
                  label="Number of Vegetarian Ticket"
                  value={
                    dataDetail?.registration?.ticketForVegetarian
                      ? dataDetail?.registration?.ticketForVegetarian
                      : DEFAULT_VALUE
                  }
                />
                <InfoText
                  label="Number of Standard Ticket"
                  value={
                    dataDetail?.registration?.ticketForStandardMeal
                      ? dataDetail?.registration?.ticketForStandardMeal
                      : DEFAULT_VALUE
                  }
                />
                <InfoText
                  label="Number of Discount Ticket"
                  value={
                    dataDetail?.registration?.numberAttendedAge
                      ? dataDetail?.registration?.numberAttendedAge
                      : DEFAULT_VALUE
                  }
                />
              </>
            )}
          </Box>
          {!isDonation && (
            <Box ml={1} mt={3} flex={1}>
              <InfoText label="Sub-total (SGD)" value={dataDetail?.registration?.subTotal ?? 0} />
              <InfoText label="Discount (SGD)" value={dataDetail?.registration?.discount ?? 0} />
              <InfoText label="Total (SGD)" value={renderTotal()} />
            </Box>
          )}
        </Box>
      </Box>
    </CustomFormDialog>
  ) : null;
}

export default PaymentHistoryDetail;
export const ButtonStatus = styled(Button)<{
  variants?: 'confirmed' | 'pending' | 'expired';
}>`
  ${({ theme, variants }) => css`
    width: 112px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'expired'
      ? theme.palette.red?.[150]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
    cursor: default;
    &:hover {
      background-color: ${variants === 'confirmed'
        ? theme.palette.yellow?.[1000]
        : variants === 'pending'
        ? theme.palette.yellow?.[150]
        : variants === 'expired'
        ? theme.palette.red?.[150]
        : null};
    }
  `}
`;
