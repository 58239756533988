import React from 'react';

import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import CloseIcon from 'src/icons/close';
import UnVerifyIcon from 'src/medias/icons/icon-error.png';
import VerifyIcon from 'src/medias/icons/icon-success.png';

const Message = styled(Typography)<{ variants: 'verify' | 'un-verify' | 'unknown' }>`
  ${({ theme, variants }) => css`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `}
  ${({ variants }) =>
    variants === 'verify' &&
    css`
      color: #34c759;
    `}
  ${({ variants }) =>
    variants === 'un-verify' &&
    css`
      color: #ff3b30;
    `}
  ${({ variants }) =>
    variants === 'unknown' &&
    css`
      display: none;
    `}
`;

const Close = styled(Box)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: ${theme.palette.yellow?.[900]};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

interface IProps {
  onRemove?: () => void;
  showClose?: boolean;
  variants: 'verify' | 'un-verify' | 'unknown';
}

const VerifyStatus = ({ onRemove, showClose, variants }: IProps) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      style={{ gap: 8 }}
      justifyContent="space-between"
    >
      <Box display="flex" style={{ gap: 8 }} justifyContent="space-between" alignItems="center">
        {variants !== 'unknown' && (
          <img
            src={variants === 'un-verify' ? UnVerifyIcon : VerifyIcon}
            alt="Verify Icon State"
            width={15}
            height={15}
          />
        )}
        <Message variants={variants}>
          {variants === 'verify' ? 'Verified Information' : 'Unverified Information'}
        </Message>
      </Box>
      {showClose && (
        <Close onClick={onRemove}>
          <CloseIcon fill="white" />
        </Close>
      )}
    </Box>
  );
};

export default VerifyStatus;
