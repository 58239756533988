import React from 'react';

import { FormikProps } from 'formik';

import { FormTextField } from 'src/pages/profile/components/general-information.styled';

import { QuestionPoint, QuestionRow, QuestionTitle, ShortQuestionContainer } from './index.styled';

interface IShortQuestionProps {
  formik: FormikProps<any>;
  questionIndex: string;
  disabled?: boolean;
  formStatus?: { isReset: boolean };
}

const ShortQuestion = ({ formik, questionIndex, disabled, formStatus }: IShortQuestionProps) => {
  const touched = formik.touched as any;
  const errors = formik.errors as any;
  const MAX_LENGTH_255 = 255;

  const handlePointChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === '-' || e.target.value === '--') return formik.setFieldValue(name, '');
    if (+e.target.value >= 0) return formik.setFieldValue(name, e.target.value);
    else return;
  };
  return (
    <ShortQuestionContainer>
      <QuestionRow variants="body">
        <QuestionTitle variants="title">
          <FormTextField variant="outlined" fullWidth placeholder="Short text answer" disabled />
        </QuestionTitle>
        <QuestionTitle variants="type" />
      </QuestionRow>
      <QuestionPoint>
        <QuestionTitle variants="title" />
        <QuestionTitle variants="type">
          <QuestionTitle variants="title">Point</QuestionTitle>
          <FormTextField
            placeholder="0"
            variant="outlined"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            onChange={handlePointChange(`question[${questionIndex}].point`)}
            name={`question[${questionIndex}].point`}
            value={formik.values.question?.[questionIndex]?.point}
            error={touched?.question?.[questionIndex]?.point && !!errors?.question?.[questionIndex]?.point}
            helperText={touched?.question?.[questionIndex]?.point && errors?.question?.[questionIndex]?.point}
            onBlur={formik.handleBlur}
            inputProps={{ maxLength: MAX_LENGTH_255 }}
            disabled={disabled}
          />
        </QuestionTitle>
      </QuestionPoint>
    </ShortQuestionContainer>
  );
};

export default ShortQuestion;
