import React from 'react';

import { Box, Typography } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import styled, { CSSProperties } from 'styled-components';

import { CustomButton } from 'src/components';
import { IResponsive } from 'src/pages/home/homepageStyled';
import useResponsive from 'src/utils/responsive';

const DialogActionsStyled = styled(DialogActions)<{ minWidth?: number }>(({ minWidth }) => ({
  justifyContent: 'center',
  paddingBottom: 24,
  minWidth: minWidth,
  maxWidth: '100%',
  overflow: 'hidden',
}));
const TypographyStyled = styled(Typography)(() => ({
  color: '#979168',
}));
const CustomButtonStyled = styled(CustomButton)(
  ({
    deleteButton,
    hiddenCancelButton,
    hiddenCloseButton,
    responsive,
  }: {
    deleteButton: boolean;
    hiddenCancelButton?: boolean;
    hiddenCloseButton?: boolean;
    responsive?: IResponsive;
  }) => ({
    fontSize: `16px !important`,
    backgroundColor: deleteButton ? '#FF1515 !important' : '#c5be8b',
    width: hiddenCloseButton ? `193px !important` : hiddenCancelButton ? `400px !important` : 193,
    maxWidth: responsive?.isTabletOrMobile ? '60%' : '100%',
  }),
);

interface ICustomDialog {
  open: boolean;
  handleClose?: () => void;
  children: any;
  modalStyles?: CSSProperties;
  onSubmit?: () => void;
  onCancel?: () => void;
  submitText?: string;
  cancelText?: string;
  disabled?: boolean;
  noClickOutSide?: boolean;
  deleteButton?: boolean;
  hiddenCancelButton?: boolean;
  hiddenCloseButton?: boolean;
  hiddenSubmitButton?: boolean;
  minHeight?: string;
  minWidth?: string;
  isConfirmPopup?: boolean;
}

export const CustomDialog = ({
  children,
  open,
  modalStyles,
  handleClose,
  onSubmit,
  onCancel,
  submitText = 'OK',
  cancelText = 'Cancel',
  disabled,
  noClickOutSide,
  deleteButton,
  hiddenCancelButton,
  hiddenCloseButton,
  hiddenSubmitButton,
  minHeight = '216px',
  minWidth,
  isConfirmPopup = false,
  ...props
}: ICustomDialog & DialogProps) => {
  const responsive = useResponsive();
  return (
    <Dialog
      {...props}
      style={{ ...modalStyles }}
      open={open}
      onClose={noClickOutSide ? () => {} : handleClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: { borderRadius: 12, minHeight, padding: 8, minWidth },
      }}
    >
      {children}
      <DialogActionsStyled minWidth={!isConfirmPopup ? 960 : undefined}>
        {!hiddenCancelButton && (
          <Box mr={2} flexBasis={'50%'} textAlign={hiddenSubmitButton ? 'center' : 'end'}>
            <CustomButton
              onClick={onCancel ? onCancel : handleClose}
              outlineButton
              maxWidth={responsive.isTabletOrMobile ? '60%' : '100%'}
            >
              <TypographyStyled style={{ lineHeight: '20px' }}>{cancelText}</TypographyStyled>
            </CustomButton>
          </Box>
        )}
        {!hiddenSubmitButton && (
          <Box flexBasis={'50%'} textAlign={hiddenCancelButton ? 'center' : 'start'}>
            <CustomButtonStyled
              hiddenCancelButton={hiddenCancelButton}
              hiddenCloseButton={hiddenCloseButton}
              deleteButton={deleteButton}
              disabled={disabled}
              onClick={onSubmit ? onSubmit : handleClose}
              responsive={responsive}
            >
              {submitText}
            </CustomButtonStyled>
          </Box>
        )}
      </DialogActionsStyled>
    </Dialog>
  );
};
