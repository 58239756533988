import React, { useState } from 'react';

import { Box, FormControlLabel, RadioGroup } from '@material-ui/core';

import { NASSRadio } from 'src/components';
import { DATE_PICKER_DISPLAY_FORMAT } from 'src/constants/common';
import { NATIONALITY } from 'src/constants/users.constants';
import { Title } from 'src/pages/profile/components/additional-information.styled';
import {
  ActionButton,
  FormMenuItem,
  FormTextField,
  GeneralInformationDatePicker,
  Label,
} from 'src/pages/profile/components/general-information.styled';
import VerifyStatus from 'src/pages/profile/shared/verify.message';
import { IApplicant } from 'src/services/gtky-applicant';

interface Props {
  onOpenEditAdditionalInformation: () => void;
  applicant: IApplicant | null;
}

const AdditionalInformationForm = ({ onOpenEditAdditionalInformation, applicant }: Props) => {
  const [isHaveChildren, setHaveChildren] = useState<boolean>(
    applicant?.child && applicant?.child.length > 0 ? true : false,
  );
  const UNVERIFIED = 'unverified';
  const VERIFIED = 'verified';

  React.useEffect(() => {
    setHaveChildren(applicant?.child && applicant?.child.length > 0 ? true : false);
  }, [applicant]);

  const checkHaveChildren = (e: React.ChangeEvent<HTMLInputElement>, v: string) => {
    setHaveChildren(v === 'no' ? false : true);
  };

  return (
    <Box px={3} pb={3}>
      <Title>Your children info</Title>
      <Box mt={3} mb={1.8}>
        <Label>Do You Have Children?</Label>
        <RadioGroup
          value={isHaveChildren && applicant?.child && applicant?.child?.length > 0 ? 'yes' : 'no'}
          onChange={checkHaveChildren}
          row
        >
          <FormControlLabel value="yes" control={<NASSRadio disabled />} label="Yes" disabled />
          <Box width={160} />
          <FormControlLabel value="no" control={<NASSRadio disabled />} label="No" disabled />
        </RadioGroup>
      </Box>
      {isHaveChildren && applicant?.child && applicant?.child?.length > 0 && (
        <Box mb={0.5} display="flex" style={{ gap: 16 }}>
          <Box flex="1">
            <Label>Child’s Surname</Label>
          </Box>
          <Box flex="1">
            <Label>Child’s Given Name</Label>
          </Box>
          <Box flex="1">
            <Label>Child’s Date Of Birth</Label>
          </Box>
          <Box flex="1">
            <Label>Nationality</Label>
          </Box>
          <Box flex="1.1" />
        </Box>
      )}

      {isHaveChildren &&
        applicant?.child?.map((item) => (
          <Box mb={3} display="flex" style={{ gap: 16 }}>
            <Box flex="1">
              <FormTextField variant="outlined" fullWidth disabled value={item.surname} />
            </Box>
            <Box flex="1">
              <FormTextField variant="outlined" fullWidth disabled value={item.givenName} />
            </Box>
            <Box flex="1">
              <GeneralInformationDatePicker
                disabled
                fullWidth
                autoOk
                variant="inline"
                inputVariant="outlined"
                format={DATE_PICKER_DISPLAY_FORMAT}
                value={item.dateOfBirth}
                InputAdornmentProps={{ position: 'end' }}
                onChange={(date) => {}}
              />
            </Box>
            <Box flex="1">
              <FormTextField
                ellipsis
                variant="outlined"
                name="gender"
                fullWidth
                disabled
                size="small"
                value={item.nationality}
                onChange={() => {}}
                select={true}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
              >
                {NATIONALITY.map(({ value, label }, idx) => (
                  <FormMenuItem key={idx} value={value}>
                    {label}
                  </FormMenuItem>
                ))}
              </FormTextField>
            </Box>
            <Box flex="1.1">
              {item?.verificationStatus === VERIFIED ? (
                <VerifyStatus variants="verify" onRemove={() => {}} />
              ) : (
                <VerifyStatus variants="un-verify" onRemove={() => {}} />
              )}
            </Box>
          </Box>
        ))}

      <Box display="flex" justifyContent="center">
        {applicant?.verificationStatus === UNVERIFIED && (
          <ActionButton variants="yellow" onClick={onOpenEditAdditionalInformation}>
            {isHaveChildren && applicant?.child && applicant?.child?.length > 0 ? 'Edit' : 'Add'}
          </ActionButton>
        )}
      </Box>
    </Box>
  );
};

export default AdditionalInformationForm;
