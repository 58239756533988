import React, { useState } from 'react';

import { Box, InputAdornment, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { CustomButton, InputField } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import DollarIconURL from 'src/medias/icons/dollar-icon.svg';
import { ImageStyled } from 'src/pages/donation/types';
import { CustomLabel, TextNote } from 'src/pages/signup/components/types';
import { paymentForMembership } from 'src/services/payment';
import { IUser } from 'src/services/users';
import useResponsive from 'src/utils/responsive';

import { steps } from '..';

import { ConfirmModal } from './ConfirmModal';

interface IConfirmation {
  me: IUser | null;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  data: {
    amount: number;
    surname: string;
    givenName: string;
    emailAddress: string;
  };
  setPaymentId: React.Dispatch<React.SetStateAction<string>>;
}

const Confirmation = ({ me, data, activeStep, setActiveStep, setPaymentId }: IConfirmation) => {
  const { isTabletOrMobile, isTablet } = useResponsive();
  const [loading, setLoading] = useState<boolean>(false);
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');
  const [isOpenModal, setOpenNotifyModal] = useState(false);
  const history = useHistory();

  const handleModal = () => {
    setOpenNotifyModal(!isOpenModal);
  };

  const _onSubmit = async () => {
    setLoading(true);
    const response = await paymentForMembership({ createNewPayment: false });

    if (response && response?.statusCode === CALL_SUCCESS) {
      setPaymentId(response?.data);
      setActionSuccess(true);
      setMessageResponse(response.message);
      setActiveStep(activeStep + 1);
    } else {
      if (response?.message === 'Found pending payment.') {
        setOpenNotifyModal(true);
      }
      setActionFailed(true);
      setMessageResponse(response?.message);
    }
    setLoading(false);
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onContinue = async () => {
    setLoading(true);
    const response = await paymentForMembership({ createNewPayment: true });

    if (response && response?.statusCode === CALL_SUCCESS) {
      setPaymentId(response?.data);
      setActionSuccess(true);
      setMessageResponse(response?.message);
      setActiveStep(activeStep + 1);
    } else {
      setActionFailed(true);
      setMessageResponse(response?.message);
    }
    setLoading(false);
    handleModal();
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {isTabletOrMobile && (
        <Box justifyContent="center" display="flex">
          <TextNote isTabletOrMobile={isTabletOrMobile}>{steps[0]}</TextNote>
        </Box>
      )}
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Box flex={1} pr={1}>
            <InputField require disabled value={data.surname} title="Surname" name="surname" margin="dense" fullWidth />
          </Box>
          <Box flex={1} pl={1}>
            <InputField
              require
              disabled
              value={data.givenName}
              title="Given Name"
              name="givenName"
              margin="dense"
              fullWidth
            />
          </Box>
        </Box>
        <Box flex={1}>
          <InputField
            disabled
            value={data.emailAddress}
            title="Email Address"
            name="emailAddress"
            margin="dense"
            fullWidth
            require
          />
        </Box>
        <Box mt={3}>
          <CustomLabel display="inline">
            Amount{' '}
            <Typography component="span" display="inline" color="error">
              *
            </Typography>
          </CustomLabel>
          <InputField
            disabled
            name="amount"
            value={data.amount}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ImageStyled src={DollarIconURL} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        mt={5}
        display="flex"
        justifyContent="center"
        flex={1}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
        mb={4}
      >
        <CustomButton style={{ width: isTablet && '100%' }} disabled={loading} onClick={_onSubmit}>
          Submit
        </CustomButton>
      </Box>
      <ConfirmModal
        open={isOpenModal}
        handleClose={handleModal}
        _onContinue={_onContinue}
        onCancel={() => history.push('/')}
        disabled={loading}
      />
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default Confirmation;
